import { defineStore } from 'pinia'

// Pinia Store
export const useConfigStore = defineStore({
  id: 'config',
  persist: [
    {
      paths: ['claimTypes', 'reasonCodes', 'partnerClaims'],
      storage: sessionStorage,
    },
  ],
  state: () => ({
    app: {
      newLogo: false, // use new logo
      devToggles: false, // show json toggles on panels
      panelRefresh: false, // refresh icon to data panels
      futurePanels: false, // show future feature panels
      profile: false, // show profile panel on dashboard
      supportChat: false, // show support chat on help page
      profileMenu: {
        lang: true, // show language profile menu
        settings: true, // show settings profile menu
      }
    },
    claimTypes: null,
    reasonCodes: null,
    partnerClaims: null,
  }),
  actions: {
    clearConfig() {
      this.claimTypes = null
      this.reasonCodes = null
      this.partnerClaims = null
    },
    claimLabel(code) {
      const matchingObject = this.claimTypes.find(item => item.code === code.toUpperCase());
      return matchingObject ? matchingObject.label : code;
    },
    reasonLabel(code) {
      const matchingObject = this.reasonCodes.find(item => item.code === code.toUpperCase());
      return matchingObject ? matchingObject.label : code;
    },
    async loadTypes() {
      if(
        !this.claimTypes ||
        !this.reasonCodes ||
        !this.partnerClaims ||
        this.claimTypes.length === 0 ||
        this.reasonCodes.length === 0 ||
        this.partnerClaims.length === 0
      ) {
        this.claimTypes = []
        this.reasonCodes = []
        this.partnerClaims = []
        const data = await $fetch(`/api/config`)

        data.forEach(item => {
          // claim types
          if(item.source_table === 'claim_type') {
            this.claimTypes.push({
              code: item.code,
              label: item.label,
              insurance: {
                ROC: item.insurance_code_roc,
                QC: item.insurance_code_qc
              }
            })
          }
          // reason codes
          else if(item.source_table === 'reason_code') {
            this.reasonCodes.push({
              code: item.code,
              label: item.label,
              type: item.type
            })
          }
          // partner claim types
          else if(item.source_table === 'client_claim_type') {
            this.partnerClaims.push({
              partnerId: item.partner_id,
              codes: item.claim_codes.split(',')
            })
          }
        })
      }
    },
  }
})
